<template>
  <div class="p-grid">
    <div class="p-col">
      <h2 class="p-text-bold p-my-2">
        Permisos del sistema
      </h2>
    </div>
    <div class="p-col-fixed" style="width:100px">
      <Button
        v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.permisos.access')"
        icon="pi pi-user-plus"
        class="p-button p-button-success p-button-rounded"
        title="asignar usuario"
        @click="handleRegister"
      />
    </div>
  </div>
  <modal-register ref="modalRegister" />
</template>
<script>
import { defineAsyncComponent, ref } from 'vue'

export default {
  name: 'titleAndButtons',
  components: {
    modalRegister: defineAsyncComponent(() => import('../modal/modal'))
  },
  setup () {
    // data
    const modalRegister = ref()
    // methods
    const handleRegister = () => {
      modalRegister.value.toggle(0)
    }
    // return
    return {
      handleRegister,
      modalRegister
    }
  }
}
</script>
<style scoped>

</style>
